package net.frozenblock.net.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("About.md", mapOf())) {
        net.frozenblock.net.components.layouts.MarkdownLayout("About") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("about-frozenblock") }) {
                org.jetbrains.compose.web.dom.Text("About FrozenBlock")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("FrozenBlock is a Minecraft mod-development team focused on creating mods with enriching experiences akin to vanilla Minecraft!")
            }
            com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
            org.jetbrains.compose.web.dom.H2(attrs = { id("projects") }) {
                org.jetbrains.compose.web.dom.Text("Projects")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("frozenlib") }) {
                org.jetbrains.compose.web.dom.Text("Frozenlib")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("FrozenBlock maintains ")
                com.varabyte.kobweb.silk.components.navigation.Link("https://modrinth.com/mod/FrozenLib") {
                    org.jetbrains.compose.web.dom.Text("FrozenLib")
                }
                org.jetbrains.compose.web.dom.Text(", a library designed to make modding easier.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("wilder-wild") }) {
                org.jetbrains.compose.web.dom.Text("Wilder Wild")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://modrinth.com/mod/wilder-wild") {
                    org.jetbrains.compose.web.dom.Text("Wilder Wild")
                }
                org.jetbrains.compose.web.dom.Text(" is a mod focused on overhauling the Wild Update, adding features and improving existing ones.")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("the-wild-mod") }) {
                org.jetbrains.compose.web.dom.Text("The Wild Mod")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("A Wild Update backport mod to 1.17/1.18")
            }
            org.jetbrains.compose.web.dom.H3(attrs = { id("caves-and-cliffs-mod") }) {
                org.jetbrains.compose.web.dom.Text("Caves and Cliffs Mod")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("A Caves and Cliffs Update backport mod to 1.16")
            }
        }
    }
}
